import {useEffect, useState} from "react";
import SwService from "./SwService";
import CountryModel from "../model/CountryModel";
import SocialWorkerModel from "../model/SocialWorkerModel";

export default function useData(): [CountryModel[], SocialWorkerModel[]] {
    const [countryList, setCountryList] = useState([] as CountryModel[]);
    const [socialWorkersList, setSocialWorkersList] = useState([] as SocialWorkerModel[]);
    useEffect(() => {
        async function getData() {
            const [countryList, socialWorkersList] = await Promise
                .all([
                        SwService.getCountryCodesIncidents(),
                        SwService.getSocialWorkers()
                    ]
                )
            if (!ignore) {
                setCountryList(countryList)
                setSocialWorkersList(socialWorkersList)
            }
        }

        let ignore = false;
        getData().then(r => r);
        return () => {
            ignore = true;
        }

    }, [])
    return [countryList, socialWorkersList];
}
