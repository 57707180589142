export default function LogoExtended() {
    return (
        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 179.7 69.4">
            <path d="M0,0V69.4c19.2,0,34.7-15.5,34.7-34.7S19.2,0,0,0Z"/>
            <path
                d="M70.9,20.8c0-4.6-3.9-6.3-9.8-7.4-2.4-.5-3-.9-3-1.5v-.1c0-.6,.6-1,1.7-1,2.1,0,4.7,.7,6.9,2.3l3.7-5.2c-2.7-2.1-6-3.1-10.4-3.1-6.3,0-9.7,3.3-9.7,7.7v.1c0,4.9,4.6,6.4,9.7,7.4,2.5,.5,3.2,.8,3.2,1.5v.1c0,.6-.6,1-2,1-2.7,0-5.5-.8-8-2.7l-4.1,4.9c2.9,2.5,7.1,3.8,11.7,3.9,6.1,0,10.1-3,10.1-7.8v-.1Z"/>
            <polygon
                points="106.9 5.3 99 5.3 95.8 16.3 92.1 5.2 85.5 5.2 81.8 16.3 78.6 5.3 70.6 5.3 78 28.4 84.7 28.4 88.7 17 92.7 28.4 99.4 28.4 106.9 5.3"/>
            <path
                d="M119.1,28.7c7.1,0,12.6-5.3,12.6-12s-5.4-11.9-12.5-11.9-12.6,5.3-12.6,12,5.4,11.9,12.5,11.9Zm0-17.1c2.9,0,4.8,2.4,4.8,5.2s-1.9,5.1-4.8,5.1-4.8-2.5-4.8-5.2,1.9-5.1,4.8-5.1Z"/>
            <path
                d="M141.3,21.6h1.7l4.3,6.6h8.8l-5.3-7.8c2.8-1.3,4.5-3.7,4.5-7v-.1c0-2.3-.7-4-2.1-5.4-1.6-1.6-4.2-2.6-8.3-2.6h-11.3V28.2h7.7v-6.6Zm.2-9.9h3.3c1.8,0,2.9,.7,2.9,2.2v.1c0,1.4-1.2,2.2-3,2.2h-3.2v-4.5Z"/>
            <path
                d="M166,5.3h-8.7V28.3h8.5c9.2,0,13.9-4.8,13.9-11.6v-.1c0-6.8-4.6-11.3-13.7-11.3Zm.1,16.2h-1.3V11.9h1.3c3.5,0,5.8,1.5,5.8,4.8s-2.3,4.8-5.8,4.8Z"/>
            <path
                d="M53.8,47.7c-1.3,0-2.2-.4-3.2-1.3l-.6,.8c1.1,1,2.3,1.4,3.7,1.3,1.9,0,3.2-1,3.2-2.6,0-1.4-.9-2.2-3-2.6-2-.4-2.5-.9-2.5-1.8s.8-1.5,2-1.5c1,0,1.8,.3,2.7,1l.6-.8c-1-.7-1.9-1.1-3.3-1.1-1.7,0-3,1.1-3,2.5,0,1.6,1,2.2,3.1,2.7,1.9,.5,2.4,.9,2.4,1.8s-.8,1.6-2.1,1.6Z"/>
            <path
                d="M63.4,39.1c-2.8,0-4.7,2.2-4.7,4.8s1.9,4.8,4.7,4.8,4.7-2.2,4.7-4.8-1.9-4.8-4.7-4.8Zm0,8.6c-2.1,0-3.6-1.7-3.6-3.8s1.5-3.8,3.6-3.8,3.6,1.7,3.6,3.8-1.5,3.8-3.6,3.8Z"/>
            <path
                d="M78.3,47.1l-.7-.7c-.9,.8-1.7,1.3-3,1.3-2.1,0-3.6-1.7-3.6-3.8s1.6-3.8,3.6-3.8c1.3,0,2.1,.6,2.9,1.3l.7-.8c-.9-.9-1.9-1.5-3.6-1.5-2.7,0-4.7,2.2-4.7,4.8s2,4.8,4.7,4.8c1.7,0,2.7-.6,3.7-1.6Z"/>
            <rect x="80.2" y="39.3" width="1" height="9.3"/>
            <path d="M87.4,39.2l-4.2,9.3h1.1l1.1-2.4h5l1.1,2.4h1.1l-4.2-9.3h-1Zm-1.6,5.9l2.1-4.7,2.1,4.7h-4.2Z"/>
            <polygon points="94.4 39.3 94.3 39.3 94.3 48.6 100.6 48.6 100.6 47.6 95.4 47.6 95.4 39.3 94.4 39.3"/>
            <polygon
                points="109.8 48.6 112.4 41.1 114.9 48.6 115.8 48.6 119.1 39.3 118 39.3 115.4 47 112.9 39.3 112 39.3 109.5 47 106.9 39.3 105.8 39.3 108.9 48.6 109.8 48.6"/>
            <path
                d="M120.3,43.9c0,2.6,1.9,4.8,4.7,4.8s4.7-2.2,4.7-4.8-1.9-4.8-4.7-4.8-4.7,2.2-4.7,4.8Zm8.4,0c0,2.1-1.5,3.8-3.6,3.8s-3.6-1.7-3.6-3.8,1.5-3.8,3.6-3.8,3.6,1.7,3.6,3.8Z"/>
            <path
                d="M133,44.9h2.6l2.7,3.6h1.3l-2.8-3.8c1.4-.3,2.5-1.2,2.5-2.7,0-.7-.2-1.3-.7-1.8-.6-.6-1.5-.9-2.6-.9h-4v9.2h1v-3.6Zm-.1-4.6h2.9c1.5,0,2.4,.7,2.4,1.8,0,1.2-1,1.9-2.4,1.9v.1h-2.9v-3.8Z"/>
            <polygon
                points="149.2 48.5 145.1 43.3 149.1 39.3 147.8 39.3 142.5 44.8 142.5 39.3 141.5 39.3 141.3 39.3 141.3 48.5 142.3 48.5 142.3 46 144.3 44 147.9 48.5 149.2 48.5"/>
            <path d="M54.1,55.1l-4.2,9.3h1.1l1.1-2.4h5l1.1,2.4h1.1l-4.2-9.3h-1Zm-1.7,5.9l2.1-4.7,2.1,4.7h-4.2Z"/>
            <polygon
                points="59.2 56.1 62.3 56.1 62.3 64.4 63.4 64.4 63.4 56.1 66.5 56.1 66.5 55.1 59.2 55.1 59.2 56.1"/>
            <path
                d="M79.7,57.8c0-.7-.2-1.3-.7-1.8-.6-.6-1.5-.9-2.6-.9h-4v9.2h1v-3.6h2.6l2.7,3.6h1.3l-2.8-3.8c1.4-.3,2.5-1.2,2.5-2.7Zm-3.4,2.1h-2.9v-3.8h2.9c1.5,0,2.4,.7,2.4,1.8,0,1.2-1,1.9-2.4,1.9v.1Z"/>
            <rect x="82.1" y="55.1" width="1" height="9.3"/>
            <path
                d="M89.1,59.1c-2-.4-2.5-.9-2.5-1.8s.8-1.5,2-1.5c1,0,1.8,.3,2.7,1l.6-.8c-1-.7-1.9-1.1-3.3-1.1-1.7,0-3,1.1-3,2.5,0,1.6,1,2.2,3.1,2.7,1.9,.5,2.4,.9,2.4,1.8s-.8,1.6-2.1,1.6-2.2-.4-3.2-1.3l-.6,.8c1.1,1,2.3,1.5,3.7,1.3,1.9,0,3.2-1,3.2-2.6,0-1.4-.9-2.2-3-2.6Z"/>
            <polygon
                points="101.9 55.2 100.6 55.2 95.3 60.7 95.3 55.1 94.3 55.1 94.1 55.1 94.1 64.4 95.1 64.4 95.1 61.9 97.1 59.9 100.7 64.4 102 64.4 97.9 59.2 101.9 55.2"/>
            <path
                d="M111,55.1h-3.2v9.2h3.2c2.9,0,4.9-2,4.9-4.6s-2-4.6-4.9-4.6Zm0,8.3h-2.2v-7.3h2.2c2.3,0,3.8,1.7,3.8,3.7s-1.5,3.6-3.8,3.6Z"/>
            <path d="M120.9,55.1l-4.2,9.3h1.1l1.1-2.4h5l1.1,2.4h1.1l-4.2-9.3h-1Zm-1.6,5.9l2.1-4.7,2.1,4.7h-4.2Z"/>
            <polygon
                points="133.4 55.1 126.1 55.1 126.1 56.1 129.2 56.1 129.2 64.4 130.3 64.4 130.3 56.1 133.4 56.1 133.4 55.1"/>
            <path d="M137.6,55.1l-4.2,9.3h1.1l1.1-2.4h5l1.1,2.4h1.1l-4.2-9.3h-1Zm-1.6,5.9l2.1-4.7,2.1,4.7h-4.2Z"/>
            <path
                d="M150,59.5c.8-.3,1.5-.9,1.5-2.1,0-.6-.2-1.1-.6-1.5-.5-.5-1.3-.8-2.4-.8h-4v9.1h4.1c2.1,0,3.4-.9,3.4-2.5,0-1.2-.9-1.9-2-2.2Zm-4.3-3.4h2.8c1.3,0,2,.6,2,1.5,0,1.1-.8,1.7-2.1,1.7h-2.7v-3.2Zm3,7.3h-3.1v-3.3h2.9c1.7,0,2.5,.6,2.5,1.6,0,1.1-.9,1.7-2.3,1.7Z"/>
            <path d="M157.6,55.1l-4.2,9.3h1.1l1.1-2.4h5l1.1,2.4h1.1l-4.2-9.3h-1Zm-1.7,5.9l2.1-4.7,2.1,4.7h-4.2Z"/>
            <path
                d="M167.7,59.1c-2-.4-2.5-.9-2.5-1.8s.8-1.5,2-1.5c1,0,1.8,.3,2.7,1l.6-.8c-1-.7-1.9-1.1-3.3-1.1-1.7,0-3,1.1-3,2.5,0,1.6,1,2.2,3.1,2.7,1.9,.5,2.4,.9,2.4,1.8s-.8,1.6-2.1,1.6-2.2-.4-3.2-1.3l-.6,.8c1.1,1,2.3,1.5,3.7,1.3,1.9,0,3.2-1,3.2-2.6,0-1.4-.9-2.2-3-2.6Z"/>
            <polygon
                points="174 60.3 179 60.3 179 59.3 174 59.3 174 56.1 179.6 56.1 179.6 55.1 172.9 55.1 172.9 55.1 172.9 64.5 179.7 64.5 179.7 63.5 174 63.5 174 60.3"/>
        </svg>
    )
}
