import styles from "./countrydata.module.css";

interface CountryDataItemInterface {
    label: string
    value: string | number | any[] | undefined
}

export default function CountryDataItem({label, value}: CountryDataItemInterface) {
    const convertArrayToString = (v: number[]): string => {
        return v.join(' | ');
    }

    return (
        <div className={styles.countryDataItem}>
            <h3 className={styles.countryDataItemLabel}>
                {label}
            </h3>
            <div className={styles.countryDataItemValue}>
                {typeof value === "object" ? convertArrayToString(value) : value}
            </div>
        </div>
    )
}
