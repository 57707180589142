interface ViolentActArgs {
    count: number
    label: string
}

export default class ViolentActChart {
    count: number;
    label: string;
    color: string = '';

    constructor({count, label}: ViolentActArgs) {
        this.count = count;
        this.label = label;
    }

    set setColor(value: string) {
        this.color = value;
    }

}
