import React from "react";
import styles from "./button.module.css";
interface ButtonProps {
    children: React.ReactNode;
    onClick?: () => void
}

export default function Button({children, onClick}: ButtonProps) {
    return (
        <button className={styles.appButton} onClick={onClick}>
            {children}
        </button>
    )
}
