import {CountryCode} from "./CountryModel";
import {ViolentActApiResponse} from "./ViolentActModel";

enum PostStatus {
    PUBLISH = "publish",
    PENDING = "pending",
    DRAFT = "draft",
    AUTO_DRAFT = "auto-draft",
    FUTURE = "future"
}

type source = {
    label: string
    url: string
}

interface SocialWorker {
    date: string
    year: string
    incident: string
    key_information: string
    name: string
    notes: string
    profession: string
    sources_list: source[] | boolean
    violent_act: ViolentActApiResponse
}

export interface SocialWorkerApiResponse {
    post_type: string
    post_status: PostStatus
    ID: number
    country_code: CountryCode
    social_worker: SocialWorker
}

interface SocialWorkerInterface {
    date: string
    year: string
    incident: string
    keyInformation: string
    name: string
    notes: string
    profession: string
    sourcesList: source[] | boolean
    type: string
    status: PostStatus
    countryId: number
    country_alpha_3_code: string
    id: number
    violentActId: number;
    violentAct: string;
}

export default class SocialWorkerModel implements SocialWorkerInterface {
    countryId: number;
    country_alpha_3_code: string;
    date: string;
    incident: string;
    keyInformation: string;
    name: string;
    notes: string;
    profession: string;
    sourcesList: source[] | boolean;
    status: PostStatus;
    type: string;
    year: string;
    id: number;
    violentActId: number;
    violentAct: string;

    constructor(args: SocialWorkerApiResponse) {
        this.countryId = args.country_code.country_id;
        this.country_alpha_3_code = args.country_code.alpha_3_code;
        this.date = args.social_worker.date;
        this.incident = args.social_worker.incident;
        this.keyInformation = args.social_worker.key_information;
        this.name = args.social_worker.name;
        this.notes = args.social_worker.notes;
        this.profession = args.social_worker.profession;
        this.sourcesList = args.social_worker.sources_list;
        this.status = args.post_status;
        this.type = args.post_type;
        this.year = args.social_worker.year;
        this.id = args.ID;
        this.violentActId = args.social_worker.violent_act.term_id;
        this.violentAct = args.social_worker.violent_act.slug;
    }

}
