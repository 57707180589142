import styles from "./mapdata.module.css";
import CountryDataItem from "../CountryData/CountryDataItem";
import MapDataClock from "./MapDataClock";

interface CoordinatesProps {
    lng?: number,
    lat?: number,
    zoom?: number,
    countriesCount: number
    incidentsCount: number
    children: JSX.Element
}

export default function MapData({lng, lat, zoom, countriesCount, incidentsCount, children}: CoordinatesProps) {

    function formatStatsText(countries: number, incidents: number): string {
        return `${incidents} incidents in ${countries} countries.`
    }

    return (
        <div className={styles.mapData}>
            <div className={styles.mapCoordinates}>
                {/*<MapDataClock />*/}
                <CountryDataItem label={'Stats'} value={formatStatsText(countriesCount, incidentsCount)}/>
                <CountryDataItem label={'Longitude'} value={lng}/>
                <CountryDataItem label={'Latitude'} value={lat}/>
                <CountryDataItem label={'Zoom'} value={zoom}/>
            </div>
            <div className={styles.mapDataButton}>
                {children}
            </div>
        </div>
    )
}
