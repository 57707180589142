import {useEffect, useState} from "react";
import ViolentActModel from "../model/ViolentActModel";
import SwService from "./SwService";

export default function useViolentAct() {
    const [violentActs, setViolentActs] = useState([] as ViolentActModel[])
    useEffect(() => {

        async function getData() {
            const violentActList = await SwService.getViolentActs();
            if (!ignore) {
                setViolentActs(violentActList);
            }
        }

        let ignore = false;
        getData().then(r => r);
        return () => {
            ignore = true;
        }

    }, []);
    return violentActs;
}
