import IconExpand from "./IconExpand";
import styles from "./link.module.css";

interface PopupLinkInterface {
    url: string
    value: string
}

export default function Link({url, value}: PopupLinkInterface) {
    return (
        <div className={styles.link}>
            <a href={url} target={'_blank'} rel={'noreferrer'}>
                <span>{value}</span>
                <span><IconExpand/></span>
            </a>
        </div>
    )
}
