import IconInfo from "./IconInfo";
import ButtonBase from "../ButtonBase/ButtonBase";
import IconInfoFill from "./IconInfoFill";

interface MapDataSwitchInterface {
    onClick: any
    expanded: boolean
}

export default function MapDataSwitch({onClick, expanded}: MapDataSwitchInterface) {
    return (
        <ButtonBase onClick={onClick}>
            {expanded ? <IconInfoFill/> : <IconInfo/>}
        </ButtonBase>
    )
}
