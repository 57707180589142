export enum ProjectionMapEnum {
    GLOBE = 'globe',
    EQUAL_EARTH = 'equalEarth',
    ALBERS = 'albers',
    MERCATOR = 'mercator',
    LAMBERT_CONFORMAL_CONIC = 'lambertConformalConic',
    WINKEL_TRIPEL = 'winkelTripel',
    NATURAL_EARTH = 'naturalEarth',
    EQUIRECTANGULAR = 'equirectangular'
}
