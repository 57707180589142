import React, {useEffect, useRef} from "react";
import SwService from "../../service/SwService";
import Spinner from "../Spinner/Spinner";

export default function ModalTeam() {
    const contentRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        SwService.getPageBySlug('team').then((response) => {
            console.log('response team: ', response);
            if (contentRef.current) {
                contentRef.current.innerHTML = response.content.rendered;
            }
        });
    }, []);
    return (
        <div ref={contentRef}>
            <Spinner/>
        </div>
    )
}
