// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import React, {useEffect, useRef, useState} from "react";
import {ProjectionMapEnum} from "../../enum/ProjectionMapEnum";
import styles from './map.module.css';
import {getMaxCountryCount} from "../../service/SwService";
import ScaleMapper from "../../utils/ScaleMapper";
import CountryIncident from "../../model/CountryIncident";
import SwitchGlobe from "../SwitchGlobe/SwitchGlobe";
import SwitchMarker from "../SwitchMarker/SwitchMarker";
import BoardConfig from "../BoardConfig/BoardConfig";
import ToggleCompass from "../ToggleCompass/ToggleCompass";
import CountryData from "../CountryData/CountryData";
import BoardInfo from "../BoardInfo/BoardInfo";
import CardData from "../CardData/CardData";
import MapData from "../MapData/MapData";
import MapDataSwitch from "../MapData/MapDataSwitch";
import CardWrapper from "../CardWrapper/CardWrapper";
import ButtonRound from "../ButtonRound/ButtonRound";
import IconPlus from "../Icons/IconPlus";
import IconMinus from "../Icons/IconMinus";
import circle from '@turf/circle';
import {point} from '@turf/helpers';
import distance from "@turf/distance";
import destination from "@turf/destination";
import tin from "@turf/tin";
import {createRoot} from "react-dom/client";
import Marker from "../MapMarker/Marker";
import {ViolentActEnum} from "../../enum/ViolentActEnum";
import SocialWorkerModel from "../../model/SocialWorkerModel";
import CountryModel from "../../model/CountryModel";
import Popup from "../MapPopup/Popup";
import BoardChart from "../BoardChart/BoardChart";
import ChartArea from "../ChartArea/ChartArea";
import SwitchFilter from "../SwitchFilter/SwitchFilter";
import Board from "../Board/Board";
import {getViolentActCounts} from "../../utils/Helpers";
import useViolentAct from "../../service/useViolentAct";
import ViolentActModel from "../../model/ViolentActModel";
import ViolentActChart from "../../model/ViolentActChart";

interface PropsInterface {
    swData: CountryIncident[]
}

export default function Map({swData}: PropsInterface) {
    const HIGHLIGHT_FILL_COLOR = 'rgba(255,72,88,1)';
    const TRANSITION_TIME = 400;
    const [loaded, setLoadedState] = useState(false);
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

    const mapContainer = useRef(null);
    const map: mapboxgl.Map | null = useRef(null);
    const [lng, setLng] = useState(-2.2);
    const [lat, setLat] = useState(52.35);
    const [zoom, setZoom] = useState(1);
    const [incidentsCount, setIncidentsCount] = useState(0);
    const [countriesCount, setCountriesCount] = useState(0);
    const [countryIncident, setCountryIncident] = useState({} as CountryIncident)
    const [projectionState, setProjectionState] = useState(false);
    const [mapDataState, setMapDataState] = useState(true);
    const [mapMarkers, setMapMarkers] = useState([] as any[]);
    const [markerState, setMarkerState] = useState(true);
    const [filterState, setFilterState] = useState(true);
    const violentActCounts = useViolentAct();
    const [violentActChartData, setViolentActData] = useState([] as ViolentActChart[]);

    useEffect(() => { // fire when props data is being changed
        if (swData.length > 0) {
            loadMap();
            setLoadedState(true);
        }
    }, [swData]);

    useEffect(() => {
        setViolentActData(ViolentActModel.convertDataToChartArea(violentActCounts));
    }, [violentActCounts]);

    useEffect(() => {
        if (countryIncident === undefined) return;
        setViolentActData(getViolentActCounts(countryIncident.socialWorkers));
    }, [countryIncident]);


    function hideMarkers() {
        for (const marker of mapMarkers) {
            marker.getElement().style.display = 'none';
        }
    }

    function showMarkers() {
        for (const marker of mapMarkers) {
            marker.getElement().style.display = 'block';
        }
    }

    // function showLayers() {
    //     map.current.setLayoutProperty('killings-network', 'visibility', 'visible');
    //     map.current.setLayoutProperty('killings-network-nodes', 'visibility', 'visible');
    //     map.current.setLayoutProperty('abuse-network', 'visibility', 'visible');
    //     map.current.setLayoutProperty('abuse-network-nodes', 'visibility', 'visible');
    //     map.current.setLayoutProperty('persecution-network', 'visibility', 'visible');
    //     map.current.setLayoutProperty('persecution-network-nodes', 'visibility', 'visible');
    //     map.current.setLayoutProperty('outline', 'visibility', 'visible');
    // }

    function hideLayers() {
        map.current.setLayoutProperty('killings-network', 'visibility', 'none');
        map.current.setLayoutProperty('killings-network-nodes', 'visibility', 'none');
        map.current.setLayoutProperty('abuse-network', 'visibility', 'none');
        map.current.setLayoutProperty('abuse-network-nodes', 'visibility', 'none');
        map.current.setLayoutProperty('persecution-network', 'visibility', 'none');
        map.current.setLayoutProperty('persecution-network-nodes', 'visibility', 'none');
        map.current.setLayoutProperty('outline', 'visibility', 'none');
    }

    function setupMap() {
        map.current = new mapboxgl.Map({
            projection: ProjectionMapEnum.MERCATOR,
            minZoom: 2,
            maxZoom: 10,
            attributionControl: false,
            container: mapContainer.current,
            style: 'mapbox://styles/kostis88/clgl4c38s00al01mjb46b2pqb',
            center: [lng, lat],
            zoom: zoom
        });
    }

    function updateCoordinates() {
        if (!map.current) return;
        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });
    }

    function convertDataToShape() {
        const countriesCount = swData.reduce((acc, curr) => acc + curr.country.incidentCount, 0);
        setIncidentsCount(countriesCount);
        setCountriesCount(swData.length)
    }

    function handleSwitchGlobe() { // Switch globe button
        projectionState ? map.current.setProjection(ProjectionMapEnum.MERCATOR) : map.current.setProjection(ProjectionMapEnum.GLOBE);
        setProjectionState(!projectionState);
    }

    function handleToggleLocate() {
        if (!Object.keys(countryIncident).length) return;
        flyToCountry(countryIncident.country.coordinates.bounding_box);
    }

    function handleMapDataSwitch() {
        setMapDataState(!mapDataState);
    }

    function handleClickZoomIn() {
        map.current.zoomIn({duration: 1000});
    }

    function handleClickZoomOut() {
        map.current.zoomOut({duration: 1000});
    }

    function handleToggleMarkers() {
        markerState ? hideMarkers() : showMarkers();
        setMarkerState(!markerState);
    }

    // function handleLayerClick() {
    //     layerState ? hideLayers() : showLayers();
    //     setLayerState(!layerState);
    // }

    function handleSwitchFilter() {
        setFilterState(!filterState);
    }

    function flyToCountry(bbox: number[]) {
        const southWest = new mapboxgl.LngLat(bbox.at(0), bbox.at(1));
        const northEast = new mapboxgl.LngLat(bbox.at(2), bbox.at(3));
        const boundingBox = new mapboxgl.LngLatBounds(southWest, northEast);

        map.current.fitBounds(boundingBox, {
            padding: 128
        });
    }

    function loadMap() {
        convertDataToShape();
        if (map.current) return; // initialize map only once
        setupMap();
        updateCoordinates();

        map.current.on('load', () => {
            // const layers = map.current.getStyle().layers;

            map.current.addSource('countries', {
                type: 'vector',
                url: 'mapbox://mapbox.country-boundaries-v1'
            });

            interface GeometryGeoJson {
                type: string
                coordinates: number[]
            }

            interface GeoJsonFeature {
                type: string
                geometry: GeometryGeoJson
                properties: {
                    violentAct: ViolentActEnum
                    socialWorker: SocialWorkerModel
                    country: CountryModel
                }
            }

            const features: any[] = [];
            const featurePoints: GeoJsonFeature[] = [];

            // TODO: Render marker function to be agile for filtering by date and violent act
            for (const data of swData) { // loop of countries
                if (data.country.incidentCount > 3) {
                    const bboxCountry: number[] = data.country.coordinates.bounding_box;
                    const fromPoint = point([bboxCountry[0], bboxCountry[1]]);
                    const toPoint = point([bboxCountry[2], bboxCountry[3]]);
                    const distanceKilo = distance(fromPoint, toPoint, {units: 'kilometers'});
                    const circleRadius = circle(data.country.coordinates.center, distanceKilo / (3.14 * 2), {
                        steps: data.country.incidentCount,
                        units: 'kilometers',
                        properties: {
                            countryId: data.country.countryId,
                            countryName: data.country.name
                        }
                    });

                    for (const polygon of circleRadius.geometry.coordinates) { // remove the first coord cause turf has a duplicate first and last
                        let idx = 0;
                        for (const point of polygon) {
                            if (idx !== (polygon.length - 1)) {

                                const proto: GeoJsonFeature = {
                                    type: 'Feature',
                                    geometry: {
                                        type: 'Point',
                                        coordinates: point
                                    },
                                    properties: {
                                        violentAct: data.socialWorkers[idx].violentAct as ViolentActEnum,
                                        socialWorker: data.socialWorkers[idx],
                                        country: data.country
                                    }
                                };
                                featurePoints.push(proto);
                                idx++;
                            }
                        }
                    }
                    features.push(circleRadius);
                }
                if (data.country.incidentCount === 1) {
                    const center: number[] = data.country.coordinates.center;
                    const proto: GeoJsonFeature = {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: center
                        },
                        properties: {
                            violentAct: data.socialWorkers[0].violentAct as ViolentActEnum,
                            socialWorker: data.socialWorkers[0],
                            country: data.country
                        }
                    };
                    featurePoints.push(proto);
                }
                if (data.country.incidentCount === 2) {
                    const point = data.country.coordinates.center;
                    const distance_a = -100;
                    const distance_b = 100;
                    const bearing_a = 0;
                    const bearing_b = 45;
                    const dest_a = destination(point, distance_a, bearing_a, {units: 'miles'});
                    const dest_b = destination(point, distance_b, bearing_b, {units: 'miles'});

                    const list: GeoJsonFeature[] = [{
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: dest_a.geometry.coordinates
                        },
                        properties: {
                            violentAct: data.socialWorkers[0].violentAct as ViolentActEnum,
                            socialWorker: data.socialWorkers[0],
                            country: data.country
                        }
                    },
                        {
                            type: 'Feature',
                            geometry: {
                                type: 'Point',
                                coordinates: dest_b.geometry.coordinates
                            },
                            properties: {
                                violentAct: data.socialWorkers[1].violentAct as ViolentActEnum,
                                socialWorker: data.socialWorkers[1],
                                country: data.country
                            }
                        }];
                    featurePoints.push(...list);
                }
                if (data.country.incidentCount === 3) {
                    const point = data.country.coordinates.center;
                    const distance_a = 0;
                    const distance_b = 200;
                    const distance_c = -200;
                    const bearing_a = 0;
                    const bearing_b = 45;
                    const bearing_c = -45;
                    const dest_a = destination(point, distance_a, bearing_a, {units: 'miles'});
                    const dest_b = destination(point, distance_b, bearing_b, {units: 'miles'});
                    const dest_c = destination(point, distance_c, bearing_c, {units: 'miles'});

                    const list: GeoJsonFeature[] = [{
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: dest_a.geometry.coordinates
                        },
                        properties: {
                            violentAct: data.socialWorkers[0].violentAct as ViolentActEnum,
                            socialWorker: data.socialWorkers[0],
                            country: data.country
                        }
                    },
                        {
                            type: 'Feature',
                            geometry: {
                                type: 'Point',
                                coordinates: dest_b.geometry.coordinates
                            },
                            properties: {
                                violentAct: data.socialWorkers[1].violentAct as ViolentActEnum,
                                socialWorker: data.socialWorkers[1],
                                country: data.country
                            }
                        },
                        {
                            type: 'Feature',
                            geometry: {
                                type: 'Point',
                                coordinates: dest_c.geometry.coordinates
                            },
                            properties: {
                                violentAct: data.socialWorkers[2].violentAct as ViolentActEnum,
                                socialWorker: data.socialWorkers[2],
                                country: data.country
                            }
                        }];

                    const collection = {
                        "type": "FeatureCollection",
                        'features': list
                    }
                    // @ts-ignore
                    const tinA = tin(collection, 'z');
                    features.push(...tinA.features)
                    featurePoints.push(...list);
                }
            }

            const mapMarkersList: any[] = [];

            for (const point of featurePoints) {
                const markerElement = document.createElement('div');
                const popUpElement = document.createElement('div');
                const root = createRoot(markerElement as HTMLElement);
                const popup = createRoot(popUpElement as HTMLElement);
                root.render(<Marker violentAct={point.properties.violentAct}/>);
                popup.render(<Popup socialWorker={point.properties.socialWorker}/>);
                const marker = new mapboxgl.Marker(markerElement)
                    .setLngLat(point.geometry.coordinates).setPopup(
                        new mapboxgl.Popup({
                            offset: 25,
                            closeButton: false,
                            maxWidth: '550px',
                            className: 'popup-container',
                        })
                            .setDOMContent(popUpElement))
                    .addTo(map.current);

                mapMarkersList.push(marker);
            }

            setMapMarkers(mapMarkersList);

            function filterBy(list: GeoJsonFeature[], violentAct: string) {
                return list.filter(e => e.properties.violentAct === violentAct);
            }

            const collection = {
                "type": "FeatureCollection",
                'features': featurePoints
            }
            // @ts-ignore
            const tinA = tin(collection, 'z');


            const killingsLayer = filterBy(featurePoints, ViolentActEnum.KILLING);
            const abusesLayer = filterBy(featurePoints, ViolentActEnum.ABUSE);
            const persecutionLayer = filterBy(featurePoints, ViolentActEnum.PERSECUTION);
            console.log('KILLINGS ONLY', killingsLayer);
            const collectionKillings = {
                "type": "FeatureCollection",
                'features': killingsLayer
            }
            const collectionAbuse = {
                "type": "FeatureCollection",
                'features': abusesLayer
            }
            const collectionPersecution = {
                "type": "FeatureCollection",
                'features': persecutionLayer
            }

            // @ts-ignore
            const collectionKillingsTin = tin(collectionKillings, 'z');
            // @ts-ignore
            const collectionAbuseTin = tin(collectionAbuse, 'z');
            // @ts-ignore
            const collectionPersecutionTin = tin(collectionPersecution, 'z');

            map.current.addSource('killings', { // KILLINGS BRO
                'type': 'geojson',
                'data': {
                    "type": "FeatureCollection",
                    'features': collectionKillingsTin.features
                }
            })

            map.current.addLayer({
                'id': 'killings-network',
                'type': 'line',
                'source': 'killings',
                'layout': {
                    'visibility': 'visible' // visible | none
                },
                'paint': {
                    'line-color': 'rgba(12, 242, 93, 0.3)',
                    'line-width': 1
                }
            });

            map.current.addLayer({
                'id': 'killings-network-nodes',
                'type': 'circle',
                'source': 'killings',
                'layout': {
                    'visibility': 'visible' // visible | none
                },
                'paint': {
                    'circle-color': 'rgba(12, 242, 93, 0.5)',
                    'circle-radius': 3
                    // 'line-width': 1
                }
            });

            map.current.addSource('abuse', { // ABUSE BRO
                'type': 'geojson',
                'data': {
                    "type": "FeatureCollection",
                    'features': collectionAbuseTin.features
                }
            })

            map.current.addLayer({
                'id': 'abuse-network',
                'type': 'line',
                'source': 'abuse',
                'layout': {
                    'visibility': 'visible' // visible | none
                },
                'paint': {
                    'line-color': 'rgba(110, 50, 225, 0.3)',
                    'line-width': 1
                }
            });

            map.current.addLayer({
                'id': 'abuse-network-nodes',
                'type': 'circle',
                'source': 'abuse',
                'layout': {
                    'visibility': 'visible' // visible | none
                },
                'paint': {
                    'circle-color': 'rgba(110, 50, 225, 0.5)',
                    'circle-radius': 3
                }
            });

            map.current.addSource('persecution', { // persecution BRO
                'type': 'geojson',
                'data': {
                    "type": "FeatureCollection",
                    'features': collectionPersecutionTin.features
                }
            })

            map.current.addLayer({
                'id': 'persecution-network',
                'type': 'line',
                'source': 'persecution',
                'layout': {
                    'visibility': 'visible' // visible | none
                },
                'paint': {
                    'line-color': 'rgba(13, 202, 240, 0.3)',
                    'line-width': 1
                }
            });

            map.current.addLayer({
                'id': 'persecution-network-nodes',
                'type': 'circle',
                'source': 'persecution',
                'layout': {
                    'visibility': 'visible' // visible | none
                },
                'paint': {
                    'circle-color': 'rgba(13, 202, 240, 0.5)',
                    'circle-radius': 3
                }
            });

            // END OF COLOR NETWORK

            map.current.addSource('incident-tringulate', { // NETWORK
                'type': 'geojson',
                'data': {
                    "type": "FeatureCollection",
                    'features': tinA.features
                }
            });

            map.current.addLayer({
                'id': 'outline-tri',
                'type': 'line',
                'source': 'incident-tringulate',
                'layout': {
                    'visibility': 'none' // visible | none
                },
                'paint': {
                    'line-color': 'rgba(180,180,180,0.8)',
                    'line-width': 1
                }
            });


            map.current.addSource('incident-points', { // polygons
                'type': 'geojson',
                'data': {
                    "type": "FeatureCollection",
                    'features': features
                }
            });

            map.current.addLayer({ // fill country polygons bounding polygon
                'id': 'maine',
                'type': 'fill',
                'source': 'incident-points', // reference the data source
                'layout': {
                    'visibility': 'none' // visible | none
                },
                'paint': {
                    'fill-color': 'rgba(72,176,252,0.3)', // blue color fill
                    'fill-opacity': 0.5
                }
            });

            map.current.addLayer({ // polygon outlines
                'id': 'outline',
                'type': 'line',
                'source': 'incident-points',
                'layout': {
                    'visibility': 'visible' // visible | none
                },
                'paint': {
                    'line-color': 'rgba(1, 253, 241, 0.7)',
                    'line-width': 1
                }
            });


            const matchExpression = ['match', ['get', 'iso_3166_1_alpha_3']];
            const matchExpressionOutline = ['match', ['get', 'iso_3166_1_alpha_3']];
            const max = getMaxCountryCount(swData);

            for (const row of swData) {
                const alpha = new ScaleMapper(1, max, 0.1, 1).scale(row.country.incidentCount);
                // const border = 'rgba(242,102,139,0.9)';
                // const blue = 0.1;
                const color = `rgba(0,187,201,${alpha})`;
                matchExpression.push(row.country.alpha_3_code, color);
                matchExpressionOutline.push(row.country.alpha_3_code, `rgba(1, 253, 241, 0.9)`);
            }

            // Last value is the default, used where there is no data
            matchExpression.push('rgba(0, 0, 0, 0)');
            matchExpressionOutline.push('rgba(0, 0, 0, 0)');

            const WORLDVIEW = "US";
            const worldview_filter = ["all", ["==", ["get", "disputed"], "false"], ["any", ["==", "all", ["get", "worldview"]], ["in", WORLDVIEW, ["get", "worldview"]]]];

            map.current.addLayer( // countries with incidents
                {
                    'id': 'countries-incidents',
                    'type': 'fill',
                    'source': 'countries',
                    'source-layer': 'country_boundaries',
                    'paint': {
                        'fill-color': matchExpression,
                        "fill-outline-color": matchExpressionOutline,
                    },
                    'filter': worldview_filter
                },
                'admin-0-boundary-bg'
            );

            map.current.addLayer( // highlight
                {
                    'id': 'countries-highlighted',
                    'type': 'fill',
                    'source': 'countries',
                    'source-layer': 'country_boundaries',
                    'paint': {
                        // 'fill-color': 'rgba(0,0,0,0)',
                        'fill-color': 'rgba(255,72,88,1)',
                        "fill-color-transition": {
                            "duration": TRANSITION_TIME,
                            "delay": 0
                        },
                        // 'fill-color': matchExpression,
                        'fill-outline-color': 'rgb(253,113,125)',
                    },
                    'filter': ['in', 'iso_3166_1_alpha_3', '']
                },
                'admin-0-boundary-bg'
            );

            map.current.addLayer( // outlines
                {
                    'id': 'countries-borders',
                    'type': 'fill',
                    'source': 'countries',
                    'source-layer': 'country_boundaries',
                    'paint': {
                        'fill-color': 'rgba(0,0,0,0)',
                        // 'fill-color': matchExpression,
                        "fill-outline-color": 'rgba(25,25,25,0.77)',
                    },
                    // 'filter': ['in', 'iso_3166_1_alpha_3', '']
                    // 'filter': worldview_filter
                },
                'admin-0-boundary-bg'
            );


            // map.current.on('mousemove', 'countries-incidents', (e: any) => {
            //     // console.log(e);
            //     const features = map.current.queryRenderedFeatures(e.point, {layers: ['countries-incidents']});
            //     // const features = map.current.queryRenderedFeatures(e.point);
            //     console.log(features[0]);
            //     if (features.length > 0) {
            //         if (hoveredStateId !== null) {
            //             map.current.setFilter('countries-borders', ['in', 'iso_3166_1_alpha_3', '']);
            //             // map.current.setFeatureState(
            //             //     { sourceLayer: 'countries-incidents', source: 'countries', id: hoveredStateId },
            //             //     { hover: false }
            //             // );
            //             // map.current.setPaintProperty('countries-incidents', 'fill-color', matchExpression)
            //
            //         }
            //         // hoveredStateId = features[0].id;
            //         setHoveredStateId(features[0].id)
            //         // map.current.setPaintProperty('countries-incidents', 'fill-color', ['match', ['get', 'iso_3166_1_alpha_3'], features[0].properties.iso_3166_1_alpha_3, 'rgba(1,0,255, 0.5)', 'rgba(0,0,0, 0)'])
            //         const fips = features.map(
            //             (feature: any) => feature.properties.iso_3166_1_alpha_3
            //         );
            //         // Set a filter matching selected features by FIPS codes
            //         // to activate the 'counties-highlighted' layer.
            //         map.current.setFilter('countries-borders', ['in', 'iso_3166_1_alpha_3', ...fips]);
            //         // map.current.setFeatureState(
            //         //     { sourceLayer: 'countries-incidents', source: 'countries', id: hoveredStateId },
            //         //     { hover: true }
            //         // );
            //     }
            // });

            // map.current.on('mouseleave', 'countries-incidents', () => {
            //     if (hoveredStateId !== null) {
            //         map.current.setFilter('countries-borders', ['in', 'iso_3166_1_alpha_3', '']);
            //         // map.current.setPaintProperty('countries-incidents', 'fill-color', matchExpression)
            //         // map.current.setFeatureState(
            //         //     { sourceLayer: 'countries-incidents', source: 'countries', id: hoveredStateId },
            //         //     { hover: false }
            //         // );
            //     }
            //     setHoveredStateId(0);
            // });

            // DISABLE THE NETWORK LAYERS
            hideLayers();

            map.current.on('click', async (e: any) => {
                const features = map.current.queryRenderedFeatures(e.point, {layers: ['countries-incidents']});
                const transitionLeave = () => {
                    return new Promise((resolve) => {
                        map.current.setPaintProperty('countries-highlighted', 'fill-color', 'rgba(0,0,0,0)');
                        setTimeout(() => {
                            map.current.setFilter('countries-highlighted', ['in', 'iso_3166_1_alpha_3', '']);
                            resolve('LEFT');
                        }, TRANSITION_TIME);
                    });
                }

                const transitionEnter = (countries: string[]) => {
                    return new Promise((resolve) => {
                        map.current.setPaintProperty('countries-highlighted', 'fill-color', 'rgba(0,0,0,0)');
                        map.current.setFilter('countries-highlighted', ['in', 'iso_3166_1_alpha_3', ...countries]);
                        map.current.setPaintProperty('countries-highlighted', 'fill-color', HIGHLIGHT_FILL_COLOR);
                        setTimeout(() => {
                            resolve('ENTERED');
                        }, TRANSITION_TIME);
                    })
                }

                if (features.length) {
                    const foundIncident = swData.find((e) => e.country.alpha_3_code === features[0].properties.iso_3166_1_alpha_3);
                    if (foundIncident !== undefined) {
                        const bbox = [
                            [e.point.x - 5, e.point.y - 5],
                            [e.point.x + 5, e.point.y + 5]
                        ];
                        // Find features intersecting the bounding box.
                        const selectedFeatures = map.current.queryRenderedFeatures(bbox, {
                            layers: ['countries-incidents']
                        });

                        const countries: string[] = selectedFeatures.map(
                            (feature: any) => feature.properties.iso_3166_1_alpha_3
                        );

                        await transitionLeave();
                        await transitionEnter(countries);
                        flyToCountry(foundIncident.country.coordinates.bounding_box);
                        setCountryIncident(foundIncident);

                        // const bboxCountry: number[] = foundIncident.country.coordinates.bounding_box;
                        // const fromPoint = point([bboxCountry[0], bboxCountry[1]]);
                        // const toPoint = point([bboxCountry[2], bboxCountry[3]]);
                        // const distanceKilo = distance(fromPoint, toPoint, {units: 'kilometers'});
                        // // const options = {steps: 10, units: 'kilometers', properties: {foo: 'bar'}};
                        // const circleRadius = circle(foundIncident.country.coordinates.center, distanceKilo / 3.14, {
                        //     steps: foundIncident.country.incidentCount,
                        //     units: 'kilometers',
                        //     properties: {foo: 'bar'}
                        // })

                        // console.log('cirlce => ', circleRadius);
                        // console.log('SOURCE GET => ', map.current.getSource('incident-points'));

                        // map.current.getSource('incident-points').setData(circleRadius);

                        // Add a new layer to visualize the polygon.
//                         map.current.addLayer({
//                             'id': 'maine',
//                             'type': 'fill',
//                             'source': 'incident-points', // reference the data source
//                             'layout': {},
//                             'paint': {
//                                 'fill-color': 'rgba(72,176,252,0.3)', // blue color fill
//                                 'fill-opacity': 0.5
//                             }
//                         });
// // Add a black outline around the polygon.
//                         map.current.addLayer({
//                             'id': 'outline',
//                             'type': 'line',
//                             'source': 'incident-points',
//                             'layout': {},
//                             'paint': {
//                                 'line-color': 'rgba(1, 253, 241, 0.9)',
//                                 'line-width': 1
//                             }
//                         });

                        // console.log('circleRadius => ', circleRadius);
                        if (countryIncident === foundIncident) {
                            console.log('hello eimai idiow',);
                        }
                    }
                }
            });
        });
    }

    return (
        <div className={styles.mapWrapper}>
            <Board>
                <BoardInfo visible={filterState}>
                    <CardWrapper expanded={mapDataState}>
                        <CardData>
                            <CountryData countriesCount={countriesCount}
                                         incidentsCount={incidentsCount} countryIncident={countryIncident}/>
                        </CardData>
                    </CardWrapper>
                    <CardData>
                        <MapData lng={lng} lat={lat} zoom={zoom} countriesCount={countriesCount}
                                 incidentsCount={incidentsCount}>
                            <MapDataSwitch onClick={handleMapDataSwitch} expanded={mapDataState}/>
                        </MapData>
                    </CardData>
                </BoardInfo>
                <BoardChart visible={filterState}>
                    <CardData>
                        <ChartArea chartData={violentActChartData}/>
                    </CardData>
                </BoardChart>
                <BoardConfig>
                    <SwitchFilter onClick={handleSwitchFilter} filterState={filterState}/>
                    <SwitchMarker onClick={handleToggleMarkers} markerState={markerState}/>
                    <ToggleCompass onClick={handleToggleLocate}/>
                    <SwitchGlobe onClick={handleSwitchGlobe} projectionState={projectionState}/>
                    <ButtonRound onClick={handleClickZoomIn}>
                        <IconPlus/>
                    </ButtonRound>
                    <ButtonRound onClick={handleClickZoomOut}>
                        <IconMinus/>
                    </ButtonRound>
                </BoardConfig>
            </Board>
            <div ref={mapContainer} className={styles.mapContainer}/>
        </div>
    )

}
