export default class ScaleMapper {
    private readonly inMin: number;
    private readonly inMax: number;
    private readonly outMin: number;
    private readonly outMax: number;
    constructor(inMin: number, inMax: number, outMin: number = 0, outMax: number) {
        this.inMin = inMin;
        this.inMax = inMax;
        this.outMin = outMin;
        this.outMax = outMax;
    }

    scale(value: number) {
        const result = (value - this.inMin) * (this.outMax - this.outMin) / (this.inMax - this.inMin) + this.outMin;

        if (result < this.outMin) {
            return this.outMin;
        } else if (result > this.outMax) {
            return this.outMax;
        }

        return result;
    }
}
