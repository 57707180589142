import styles from "./header.module.css";
import CardData from "../CardData/CardData";
import React, {useState} from "react";
import Logo from "../Icons/Logo";
import Modal from "../Modal/Modal";
import ModalAbout from "../ModalAbout/ModalAbout";
import ModalTeam from "../ModalTeam/ModalTeam";
import ModalContact from "../ModalContact/ModalContact";
import ModalInfo from "../ModalInfo/ModalInfo";

interface HeaderInterface {
    children?: JSX.Element | JSX.Element[]
}

export default function Header({children}: HeaderInterface) {
    const [selectedModal, setSelectedModal] = useState<string | null>(null);

    const handleModalVisibleState = (value: string) => {
        if (selectedModal === value) {
            setSelectedModal(null);
        } else {
            setSelectedModal(value);
        }
    }

    return (
        <>
            <div className={styles.header}>
                <CardData>
                    <div className={styles.headerNav}>
                        <div className={styles.headerBrand}>
                            <Logo/>
                        </div>
                        <div className={styles.headerMenu}>
                            <button className={selectedModal === 'about' ? styles.active : ''}
                                    onClick={() => handleModalVisibleState('about')} type={'button'}>About
                            </button>
                            <button className={selectedModal === 'info' ? styles.active : ''}
                                    onClick={() => handleModalVisibleState('info')} type={'button'}>Info
                            </button>
                            <button className={selectedModal === 'team' ? styles.active : ''}
                                    onClick={() => handleModalVisibleState('team')} type={'button'}>Team
                            </button>
                            <button className={selectedModal === 'contact' ? styles.active : ''}
                                    onClick={() => handleModalVisibleState('contact')} type={'button'}>Contact
                            </button>
                        </div>
                    </div>
                </CardData>
            </div>
            <Modal onClose={() => setSelectedModal(null)} visible={selectedModal === 'about'}>
                {selectedModal === 'about' ? <ModalAbout/> : null}
            </Modal>
            <Modal onClose={() => setSelectedModal(null)} visible={selectedModal === 'info'}>
                {selectedModal === 'info' ? <ModalInfo/> : null}
            </Modal>
            <Modal onClose={() => setSelectedModal(null)} visible={selectedModal === 'team'}>
                {selectedModal === 'team' ? <ModalTeam/> : null}
            </Modal>
            <Modal onClose={() => setSelectedModal(null)} visible={selectedModal === 'contact'}>
                {selectedModal === 'contact' ? <ModalContact/> : null}
            </Modal>
        </>
    )
}
