import IconGlobe from "./IconGlobe";
import IconMap from "./IconMap";
import ButtonRound from "../ButtonRound/ButtonRound";

export default function SwitchGlobe({onClick, projectionState}: any) {

    return (
        <ButtonRound onClick={onClick}>
            {projectionState ? <IconMap/> : <IconGlobe/>}
        </ButtonRound>
    )
}
