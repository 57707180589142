import React from "react";
import styles from './modal.module.css';
import IconCloseLg from "../Icons/IconCloseLg";

type ModalProps = {
    children: React.ReactNode,
    visible: boolean,
    onClose: () => void
}
export default function Modal({children, visible, onClose}: ModalProps) {
    return (
        <main className={`${styles.modal} ${visible ? styles.enter : styles.leave}`}>
            <div className={styles.modalLayout}>
                <div className={styles.modalClose}>
                    <button type={'button'} onClick={onClose}>
                        <IconCloseLg/>
                    </button>
                </div>
                <div className={styles.modalText}>
                    { visible ? children : null}
                </div>
            </div>
        </main>
    )
}
