import React, {forwardRef, useEffect, useState} from "react";
import styles from "./cardwrapper.module.css";
interface CardDataInterface {
    children: JSX.Element,
    expanded: boolean
}

const CardWrapper: React.ForwardRefExoticComponent<any> = forwardRef(({
                                                                          children,
                                                                          expanded
                                                                      }: CardDataInterface, ref: React.ForwardedRef<any>) => {
    const [stateClass, setStateClass] = useState('appear-bottom');

    useEffect(() => {
        expanded ? setStateClass('appear-bottom') : setStateClass('leave-bottom');
    }, [expanded])

    return (
        <div ref={ref} className={`${stateClass} ${styles.cardWrapper}`}>
            {children}
        </div>
    )
});

export default CardWrapper;
