import ButtonRound from "../ButtonRound/ButtonRound";
import IconSliders from "./IconSliders";
import {MouseEventHandler} from "react";

interface SwitchFilterInterface {
    onClick: MouseEventHandler
    filterState?: boolean
}

export default function SwitchFilter({onClick, filterState}: SwitchFilterInterface) {
    return (
        <ButtonRound onClick={onClick} state={filterState}>
            <IconSliders/>
        </ButtonRound>
    )
}
