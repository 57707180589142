import styles from './spinner.module.css';

export default function Spinner() {
    return (
        <div className={styles.spinnerContainer}>
            <div className={styles.spinner} role="status">
            </div>
        </div>
    )
}
