import React, {useEffect, useRef} from "react";
import SwService from "../../service/SwService";
import Spinner from "../Spinner/Spinner";

export default function ModalAbout() {
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        SwService.getPageBySlug('about').then((response) => {
            if (contentRef.current) {
                contentRef.current!.innerHTML = response.content.rendered;
            }
        });
    }, []);

    return (
        <div ref={contentRef}>
            <Spinner/>
        </div>
    )
}
