import CountryModel from "./CountryModel";
import SocialWorkerModel from "./SocialWorkerModel";

export default class CountryIncident {
    readonly country: CountryModel;
    readonly socialWorkers: SocialWorkerModel[]

    constructor(country: CountryModel, socialWorkers: SocialWorkerModel[]) {
        this.country = country;
        this.socialWorkers = socialWorkers;
    }
}
