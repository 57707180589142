import ButtonRound from "../ButtonRound/ButtonRound";
import IconCompass from "./IconCompass";

export default function ToggleCompass({onClick}: any) {
    return (
        <ButtonRound onClick={onClick}>
            <IconCompass/>
        </ButtonRound>
    )
}
