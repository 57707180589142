export default function Logo() {
    return (
        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 179.7 69.4">
            <path d="M0,0V69.4c19.2,0,34.7-15.5,34.7-34.7S19.2,0,0,0Z"/>
            <path
                d="M61.1,30.2c-2.4-.5-3-.9-3-1.5v-.1c0-.6,.6-1,1.7-1,2.1,0,4.7,.7,6.9,2.3l3.7-5.2c-2.7-2.1-6-3.1-10.4-3.1-6.3,0-9.7,3.3-9.7,7.7v.1c0,4.9,4.6,6.4,9.7,7.4,2.5,.5,3.2,.8,3.2,1.5v.1c0,.6-.6,1-2,1-2.7,0-5.5-.8-8-2.7l-4.1,4.9c2.9,2.5,7.1,3.8,11.7,3.9,6.1,0,10.1-3,10.1-7.8v-.1c0-4.6-3.9-6.3-9.8-7.4Z"/>
            <polygon
                points="99 22.1 95.8 33.1 92.1 22 85.5 22 81.8 33.1 78.6 22.1 70.6 22.1 78 45.2 84.7 45.2 88.7 33.8 92.7 45.2 99.4 45.2 106.9 22.1 99 22.1"/>
            <path
                d="M119.2,21.6c-7.1,0-12.6,5.3-12.6,12s5.4,11.9,12.5,11.9,12.6-5.3,12.6-12-5.4-11.9-12.5-11.9Zm-.1,17.1c-2.9,0-4.8-2.5-4.8-5.2s1.9-5.1,4.8-5.1,4.8,2.4,4.8,5.2-1.9,5.1-4.8,5.1Z"/>
            <path
                d="M155.3,30.2v-.1c0-2.3-.7-4-2.1-5.4-1.6-1.6-4.2-2.6-8.3-2.6h-11.3v22.9h7.7v-6.6h1.7l4.3,6.6h8.8l-5.3-7.8c2.8-1.3,4.5-3.7,4.5-7Zm-7.6,.6c0,1.4-1.2,2.2-3,2.2h-3.2v-4.5h3.3c1.8,0,2.9,.7,2.9,2.2v.1Z"/>
            <path
                d="M166,22.1h-8.7v23h8.5c9.2,0,13.9-4.8,13.9-11.6v-.1c0-6.8-4.6-11.3-13.7-11.3Zm.1,16.2h-1.3v-9.6h1.3c3.5,0,5.8,1.5,5.8,4.8s-2.3,4.8-5.8,4.8Z"/>
        </svg>
    )
}
