import styles from "./countrydata.module.css";
import CountryIncident from "../../model/CountryIncident";
import CountryDataItem from "./CountryDataItem";

interface CountryDataInterface {
    countryIncident: CountryIncident
    countriesCount: number
    incidentsCount: number
}

export default function CountryData({countryIncident, countriesCount, incidentsCount}: CountryDataInterface) {

    function formatStatsText(countries: number, incidents: number): string {
        return `${incidents} incidents in ${countries} countries.`
    }

    if (Object.keys(countryIncident).length) {
        return (
            <div className={styles.countryDataContainer}>
                <div className={styles.countryDataGrid}>
                    <CountryDataItem label={'Incidents'} value={countryIncident.country.incidentCount}/>
                    <CountryDataItem label={'Country'} value={countryIncident.country.name}/>
                    <CountryDataItem label={'Official Name'} value={countryIncident.country.officialName}/>
                    <CountryDataItem label={'Sovereign State'} value={countryIncident.country.sovereignState}/>
                </div>
                <div className={styles.countryDataGrid}>
                    <CountryDataItem label={'Region'} value={countryIncident.country.region}/>
                    <CountryDataItem label={'Sub Region'} value={countryIncident.country.subRegion}/>
                    <CountryDataItem label={'ISO 3166 code'} value={countryIncident.country.alpha_3_code}/>
                    <CountryDataItem label={'Coordinates'} value={countryIncident.country.coordinates.center}/>
                </div>
            </div>
        )
    } else {
        return (
            <div className={styles.countryDataGrid}>
                <CountryDataItem label={'Stats'} value={formatStatsText(countriesCount, incidentsCount)}/>
            </div>
        )
    }
}
