import {ViolentActEnum} from "../../enum/ViolentActEnum";
import styles from "./marker.module.css";


interface MarkerPropsInterface {
    violentAct: ViolentActEnum
}

export default function Marker({violentAct}: MarkerPropsInterface) {

    return (
        <div className={styles.marker}>
            <div className={styles.markerIcon} data-violent-act={violentAct}>
            </div>
        </div>
    )
}
