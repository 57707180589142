import {ViolentActEnum} from "../../enum/ViolentActEnum";
import styles from "./pillviolentact.module.css";
import {useEffect, useState} from "react";

interface PillPropsInterface {
    violentAct: ViolentActEnum
}

export default function PillViolentAct({violentAct}: PillPropsInterface) {
    const [pillState, setPillState] = useState('');

    useEffect(() => {
        setPillState(violentAct as ViolentActEnum);
    }, [violentAct]);

    return (
        <div className={styles.pillViolentAct} data-pill={pillState}>
            {pillState}
        </div>
    )
}
