import {TaxonomyEnum} from "../enum/TaxonomyEnum";
import {ViolentActEnum} from "../enum/ViolentActEnum";
import ViolentActChart from "./ViolentActChart";

export interface ViolentActApiResponse {
    term_id: number,
    name: string,
    slug: ViolentActEnum,
    term_group: number,
    term_taxonomy_id: number,
    taxonomy: TaxonomyEnum,
    description: string,
    parent: number,
    count: number,
    filter: string
}

interface ViolentActInterface {
    name: string
    count: number
    taxonomy: string
    description: string
    violentActId: number
    violentAct: ViolentActEnum
}

export default class ViolentActModel implements ViolentActInterface {
    count: number;
    description: string;
    name: string;
    taxonomy: string;
    violentAct: ViolentActEnum;
    violentActId: number;

    constructor(args: ViolentActApiResponse) {
        this.count = args.count;
        this.description = args.description;
        this.name = args.name;
        this.taxonomy = args.taxonomy;
        this.violentAct = args.slug;
        this.violentActId = args.term_id;
    }

    public static convertDataToChartArea(violentActs: ViolentActModel[]): ViolentActChart[] {
        return violentActs.map((e) => {
            return new ViolentActChart({
                count: e.count,
                label: e.violentAct
            })
        })

    }
}
