import React, {useEffect, useState} from 'react';
import Map from "./components/Map/Map";
import 'mapbox-gl/dist/mapbox-gl.css';
import './App.css';
import useData from "./service/useData";
import {aggregateIncidentsToCountries} from "./service/SwService";
import CountryIncident from "./model/CountryIncident";
import Header from "./components/Header/Header";
import Landing from "./components/Landing/Landing";

function App() {
    const [mapData, setMapData] = useState([] as CountryIncident[]);
    const [countryList, socialWorkersList] = useData();
    const [loaded, setLoadedState] = useState(false);

    useEffect(() => {
        if (countryList.length && socialWorkersList.length) {
            const mapData = aggregateIncidentsToCountries(countryList, socialWorkersList);
            setMapData(mapData);
            setLoadedState(true);
        }
    }, [countryList, socialWorkersList]);

    const handleAppTrigger = () => {
        console.log('load the app');
    }

    return (
        <div className="App">
            <Header/>
            <Landing onTrigger={() => handleAppTrigger()}/>
            {loaded ? (<Map swData={mapData}/>) : null}
        </div>
    );
}

export default App;
