import styles from "./boardchart.module.css";
import {useEffect, useState} from "react";

interface ChartBoardPropsInterface {
    children?: JSX.Element
    visible?: boolean
}

export default function BoardChart({children, visible}: ChartBoardPropsInterface) {
    const [boardChartState, setBoardChartState] = useState('');
    useEffect(() => {
        visible ? setBoardChartState('visible') : setBoardChartState('hidden');
    }, [visible]);
    return (
        <div className={styles.chartBoard} data-state={boardChartState}>
            {children}
        </div>
    )
}
