import styles from "./popup.module.css";
import SocialWorkerModel from "../../model/SocialWorkerModel";
import IconPersonBadge from "../Icons/IconPersonBadge";
import PillViolentAct from "../PillViolentAct/PillViolentAct";
import {ViolentActEnum} from "../../enum/ViolentActEnum";
import CountryDataItem from "../CountryData/CountryDataItem";
import Link from "./Link";

interface PopupPropsInterface {
    socialWorker: SocialWorkerModel
}

export default function Popup({socialWorker}: PopupPropsInterface) {

    return (
        <div className={styles.popup}>
            <div className={styles.popupContent}>
                <div className={styles.popupHeader}>
                    <IconPersonBadge/>
                    <h2>
                        {socialWorker.name}
                    </h2>
                    <PillViolentAct violentAct={socialWorker.violentAct as ViolentActEnum}/>
                </div>
                <div className={styles.popupContentRow}>
                    <CountryDataItem label={'Incident'} value={socialWorker.incident}/>
                    <CountryDataItem label={'Year'} value={socialWorker.year}/>
                    <CountryDataItem label={'Date'} value={socialWorker.date}/>
                </div>
                <div className={styles.popupContentRow}>
                    <CountryDataItem label={'Profession'} value={socialWorker.profession}/>
                </div>
                <div className={styles.popupContentRow}>
                    <div className={styles.popupContentInformation}>
                        <CountryDataItem label={'Information'} value={''}/>
                        <section dangerouslySetInnerHTML={{ __html: socialWorker.keyInformation }} />
                    </div>
                </div>
                {typeof socialWorker.sourcesList !== "boolean" ?
                    <div className={styles.popupSourcesContainer}>
                        <div className={styles.popupContentRow}>
                            <CountryDataItem label={'Sources'} value={''}/>
                        </div>
                        <div className={styles.popupSourcesList}>
                            {socialWorker.sourcesList?.map((source, index) => {
                                return (
                                    <Link key={index} value={source.label} url={source.url}/>
                                )
                            })}
                        </div>
                    </div>
                    : null}
            </div>
        </div>
    )
}
