import SocialWorkerModel from "../model/SocialWorkerModel";
import {ViolentActEnum} from "../enum/ViolentActEnum";
import ViolentActChart from "../model/ViolentActChart";

export function getViolentActCounts(socialWorkers: SocialWorkerModel[]): ViolentActChart[] {
    const violentActs = socialWorkers?.map(e => e.violentAct);
    if (typeof violentActs === 'undefined') return [];

    let killingCount = 0;
    let abuseCount = 0;
    let persecutionCount = 0;
    for (const act of violentActs) {
        if (act === ViolentActEnum.KILLING) {
            killingCount++;
        }
        if (act === ViolentActEnum.ABUSE) {
            abuseCount++;
        }
        if (act === ViolentActEnum.PERSECUTION) {
            persecutionCount++;
        }
    }

    const violentActCounts = {
        killing: killingCount,
        abuse: abuseCount,
        persecution: persecutionCount
    }

    const violentActChartData: ViolentActChart[] = [];

    for (const [key, value] of Object.entries(violentActCounts)) {
        violentActChartData.push(new ViolentActChart({
            count: value,
            label: key,
        }));
    }
    return violentActChartData;
}
