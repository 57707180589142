import {useEffect, useRef, useState} from "react";
import {Chart} from "chart.js/auto";
import ViolentActChart from "../../model/ViolentActChart";
import {ViolentActColorEnum, ViolentActEnum} from "../../enum/ViolentActEnum";

interface ChartAreaInterface {
    chartData?: ViolentActChart[]
}

export default function ChartArea({chartData}: ChartAreaInterface) {
    const chartAreaElement = useRef(null);
    const chart = useRef(null);

    useEffect(() => {
        if (chartData?.length && chartData !== undefined) {
            setColorsToLabels();
            updateChartData();
        }
    }, [chartData]);

    useEffect(() => {
        console.log('CHART IS LOADED', chartAreaElement);
        if (chartAreaElement.current !== null) {
            setupChart();
        }
    }, [chartAreaElement]);

    function setColorsToLabels() {
        chartData?.map((e) => {
            if (e.label === ViolentActEnum.PERSECUTION) {
                e.setColor = ViolentActColorEnum.PERSECUTION;
            }
            if (e.label === ViolentActEnum.KILLING) {
                e.setColor = ViolentActColorEnum.KILLING
            }
            if (e.label === ViolentActEnum.ABUSE) {
                e.setColor = ViolentActColorEnum.ABUSE
            }
        });
    }


    function updateChartData() {
        if (chartData === undefined) return;
        // @ts-ignore
        chart.current.data.datasets[0].data = chartData.filter(e => e.count !== 0).map(e => e.count);
        // @ts-ignore
        chart.current.data.labels = chartData.filter(e => e.count !== 0).map(e => e.label);
        // @ts-ignore
        chart.current.data.datasets[0].backgroundColor = chartData.filter(e => e.count !== 0).map(e => e.color);
        // @ts-ignore
        chart.current.update();
    }

    const data = {
        datasets: [
            {
                label: 'Violent Acts',
                data: [],
                backgroundColor: []
            }
        ]
    };

    const config = {
        type: 'polarArea',
        data: data,
        options: {
            // elements: {
            //     arc: {
            //         borderColor: '#fff'
            //     }
            // },
            scales: {
                r: {
                    grid: {
                        color: 'rgba(218,218,218,0.6)'
                    }
                    // type: 'radialLinear',
                    // position: 'right', // `axis` is determined by the position as `'y'`
                }
            },
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: 'rgba(218,218,218,0.5)',
            borderJoinStyle: 'bevel',
            borderWidth: 1,
            responsive: true,
            plugins: {
                legend: {
                    position: 'bottom',
                    labels: {
                        useBorderRadius: true,
                        borderRadius: '20px',
                        color: 'rgba(218,218,218,0.7)',
                        usePointStyle: true,
                        pointStyle: 'circle'
                    }
                },
                title: {
                    display: true,
                    text: 'Violent Acts',
                    color: 'white'
                },
                subtitle: {
                    display: true,
                    text: '',
                    padding: '10px',
                    color: 'rgba(218,218,218,0.7)',
                }
            }
        },
    };

    function setupChart() {
        // @ts-ignore
        chart.current = new Chart(chartAreaElement.current, config);
    }

    return (
        <canvas ref={chartAreaElement}>
        </canvas>
    )
}
