import SwEndpoints from "./SwEndpoints";
import CountryModel, {CountryApiResponse} from "../model/CountryModel";
import SocialWorkerModel, {SocialWorkerApiResponse} from "../model/SocialWorkerModel";
import CountryIncident from "../model/CountryIncident";
import ViolentActModel, {ViolentActApiResponse} from "../model/ViolentActModel";


export default class SwService {

    public static async getSocialWorkers(): Promise<SocialWorkerModel[]> {
        try {
            return fetch(SwEndpoints.SOCIAL_WORKERS)
                .then((r) => r.json())
                .then((response: SocialWorkerApiResponse[]) => {
                    return response.map((e) => {
                        return new SocialWorkerModel(e);
                    });
                })
        } catch (e) {
            console.error(e);
            throw new Error('Error: @ getSocialWorkers() method');
        }
    }

    public static async getCountryCodesAll(): Promise<CountryModel[]> {
        try {
            return fetch(SwEndpoints.COUNTRY_CODES + '?hide_empty=false')
                .then(r => r.json())
                .then((response: CountryApiResponse[]) => {
                    return response.map((country: CountryApiResponse) => {
                        return new CountryModel(country)
                    })
                })

        } catch (e) {
            console.error(e);
            throw new Error('Error: @ getCountryCodes() method');
        }
    }

    public static async getCountryCodesIncidents(): Promise<CountryModel[]> {
        try {
            return fetch(SwEndpoints.COUNTRY_CODES + '?hide_empty=true')
                .then(r => r.json())
                .then((response: CountryApiResponse[]) => {
                    return response.map((country: CountryApiResponse) => {
                        return new CountryModel(country)
                    })
                });
        } catch (e) {
            console.error(e);
            throw new Error('Error: @ getCountryCodes() method');
        }
    }

    public static async getViolentActs() {
        try {
            return fetch(SwEndpoints.VIOLENT_ACTS)
                .then(r => r.json()).then((response: ViolentActApiResponse[]) => {
                    return response.map((violentAct: ViolentActApiResponse) => {
                        return new ViolentActModel(violentAct);
                    })
                })
        } catch (e) {
            console.error(e);
            throw new Error('Error: @ getViolentActs() method')
        }
    }

    public static async getPageBySlug(slug: string){
        try {
            return fetch(`${SwEndpoints.PAGE_BY_SLUG}?slug=${slug}`)
                .then(response => response.json())
                .then((response) => {
                    if (!response.length) {
                       throw new Error('No page found')
                    }
                    return response[0]
                })

        } catch (e) {
            return Promise.reject(e);
        }

    }

}

export function getMaxCountryCount(list: CountryIncident[]): number {
    const listCounts: number[] = list.map(e => e.country.incidentCount);
    const max = Math.max(...listCounts);
    return max;
}


export function aggregateIncidentsToCountries(countryList: CountryModel[], socialWorkersList: SocialWorkerModel[]): CountryIncident[] {
    return countryList.map((country: CountryModel) => {
        const getSocialWorkersByCountry = (): SocialWorkerModel[] => {
            return socialWorkersList.filter(socialWorker => socialWorker.countryId === country.countryId)
        }
        return new CountryIncident(country, getSocialWorkersByCountry());
    })
}
