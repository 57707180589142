import styles from "./boardinfo.module.css";
import {useEffect, useState} from "react";

interface BoardInfoInterface {
    children: JSX.Element[],
    visible?: boolean
}

export default function BoardInfo({children, visible}: BoardInfoInterface) {
    const [boardInfoState, setBoardInfoState] = useState('');
    useEffect(() => {
        visible ? setBoardInfoState('visible') : setBoardInfoState('hidden');
    }, [visible]);
    return (
        <div className={styles.boardInfo} data-state={boardInfoState}>
            {children}
        </div>
    )
}
