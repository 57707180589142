import styles from "./carddata.module.css";
import React from "react";

interface CardDataInterface {
    children: JSX.Element
}

export default function CardData({children}: CardDataInterface) {
    return (
        <div className={styles.cardData}>
            {children}
        </div>
    )
}

