import styles from "./boardconfig.module.css";

interface BoardConfigInterface {
    children?: JSX.Element[]
}

export default function BoardConfig({children}: BoardConfigInterface) {

    return (
        <div className={styles.configBoard}>
            {children}
        </div>
    )
}
