export enum ViolentActEnum {
    ABUSE = 'abuse',
    KILLING = 'killing',
    PERSECUTION = 'persecution'
}

export enum ViolentActColorEnum {
    ABUSE = 'rgba(110, 50, 225, 0.81)',
    KILLING = 'rgba(12, 242, 93, 0.81)',
    PERSECUTION = 'rgba(13, 202, 240, 0.81)'
}
