type coordinates = {
    center: number[],
    bounding_box: number[]
}

export interface CountryCode {
    country_id: number
    alpha_2_code: string
    alpha_3_code: string
    country_name: string
    official_state_name: string
    sovereign_state: string
    internet_cctld: string
    numeric_code: number
    region: string
    sub_region: string
    region_code: number
    sub_region_code: number
    subdivision_codelinks: string
    coordinates: coordinates
}

export interface CountryApiResponse {
    country_code: CountryCode
    count: number
    description: string
    name: string
    parent: number
    slug: string
    taxonomy: string
    term_group: number
    term_id: number
    term_taxonomy_id: number
    filter: string
}

interface CountryModelInterface {
    incidentCount: number
    taxonomy: string
    countryId: number
    name: string
    officialName: string
    region: string
    subRegion: string
    sovereignState: string
    alpha_3_code: string
    coordinates: coordinates

}

export default class CountryModel implements CountryModelInterface {
    alpha_3_code: string;
    countryId: number;
    incidentCount: number;
    name: string;
    officialName: string;
    region: string;
    subRegion: string;
    sovereignState: string;
    taxonomy: string;
    coordinates: coordinates


    constructor(args: CountryApiResponse) {
        this.countryId = args.country_code.country_id;
        this.alpha_3_code = args.country_code.alpha_3_code;
        this.incidentCount = args.count;
        this.name = args.country_code.country_name;
        this.officialName = args.country_code.official_state_name;
        this.region = args.country_code.region;
        this.subRegion = args.country_code.sub_region;
        this.sovereignState = args.country_code.sovereign_state;
        this.taxonomy = args.taxonomy;
        this.coordinates = args.country_code.coordinates;
    }
}
