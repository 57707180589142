import styles from './landing.module.css';
import {useEffect, useRef, useState} from "react";
import SwService from "../../service/SwService";
import Button from "../Button/Button";
import LogoExtended from "../Icons/LogoExtended";
import Spinner from "../Spinner/Spinner";

interface LandingProps {
    onTrigger: () => void
}

export default function Landing({onTrigger}: LandingProps) {
    const [loading, setLoading] = useState<boolean>(false);
    const contentRef = useRef<HTMLDivElement>(null);
    const [landingVisible, setLandingVisible] = useState<boolean>(true);

    useEffect(() => {
        setLoading(true);

        async function getData() {
            const response = await SwService.getPageBySlug('landing');
            contentRef.current!.innerHTML = response.content.rendered;
            setLoading(false);
        }

        getData().then(e => e);
    }, []);

    const handleTriggerClick = () => {
        setLandingVisible(false);
        onTrigger();
    }

    return (
        <main className={`${styles.landing} ${landingVisible ? styles.visible : styles.hidden}`}>
            <div className={styles.landingLayout}>
                <LogoExtended/>
                <div ref={contentRef} className={styles.landingContent}>
                </div>
                {loading ? <Spinner/> : null}
                <Button onClick={handleTriggerClick}>
                    Explore
                </Button>
            </div>
        </main>
    )
}
