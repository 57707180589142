import styles from "./board.module.css";

interface BoardInterface {
    children?: JSX.Element[]
}

export default function Board({children}: BoardInterface) {
    return (
        <div className={styles.board}>
            {children}
        </div>
    )
}
