import ButtonRound from "../ButtonRound/ButtonRound";
import IconLocate from "./IconLocate";
import {MouseEventHandler} from "react";

interface SwitchMarkerInterface {
    onClick: MouseEventHandler
    markerState?: boolean
}

export default function SwitchMarker({onClick, markerState}: SwitchMarkerInterface) {
    return (
        <ButtonRound onClick={onClick} state={markerState}>
            <IconLocate/>
        </ButtonRound>
    )
}
