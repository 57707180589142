import styles from './buttonround.module.css';
import {MouseEventHandler, useEffect, useState} from "react";

interface ButtonRoundInterface {
    children?: JSX.Element
    onClick: MouseEventHandler
    state?: boolean
}

export default function ButtonRound({children, onClick, state}: ButtonRoundInterface) {
    const [buttonState, setButtonState] = useState('inactive');

    useEffect(() => {
        state ? setButtonState('active') : setButtonState('inactive');
    }, [state]);

    return (
        <button onClick={onClick} className={styles.buttonRound} data-state={buttonState}>
            {children}
        </button>
    )
}
