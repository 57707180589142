export default class SwEndpoints {
    private static BASE_URL: string | undefined = process.env.REACT_APP_BASE_URL;
    private static BASE_API: string = 'wp-json/wp/v2/';

    private static get baseApi() {
        const base = new URL(this.BASE_URL as string);
        base.pathname = this.BASE_API;
        return base.href;
    }

    public static SOCIAL_WORKERS = this.baseApi + 'sw';
    public static COUNTRY_CODES = this.baseApi + 'cc';
    public static VIOLENT_ACTS = this.baseApi + 'violent-acts';
    public static PAGE_ABOUT = this.baseApi + 'about';
    public static PAGE_LANDING = this.baseApi + 'landing';
    public static PAGE_TEAM = this.baseApi + 'team';
    public static PAGE_CONTACT = this.baseApi + 'contact';
    public static PAGE_BY_SLUG = this.baseApi + 'pages';
}
